.container {
  position: relative;
}

.header {
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 3;
}

.header h1 {
  padding-left: 50px;
}

.header h2 {
  padding-top: 8px;
  padding-right: 50px;
}

.showcase {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
}

.video-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

.video-container video {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.video-container:after {
  content: "";
  z-index: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: rgba(24, 23, 23, 0.3);
}

.content {
  z-index: -1;
}

.area-login {
  font-family: "Raleway" !important;
  position: absolute;
  width: 380px;
  height: 400px;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  padding-top: 30px;
  transform: translate(-50%, -50%);
  z-index: 1;
  background: rgba(24, 23, 23, 0.3);
}

.area-login h1 {
  text-align: center;
}

.input-login {
  margin-left: 25px !important;
}

.input-login input {
  width: 330px;
  background-color: transparent !important;
}

.input-login .MuiInput-underline::before {
  border-bottom: 1px solid white !important;
}

.input-login .MuiInput-underline::after {
  border-bottom: none !important;
}

#button-login {
  background-image: linear-gradient(
    to right,
    #303233 0%,
    #414345 51%,
    #232526 100%
  );

  width: 120px !important;
  text-align: center !important;
  text-transform: none !important;
  transition: 0.5s !important;
  background-size: 200% auto !important;
  border-radius: 10px !important;
  display: block !important;
  align-self: center;
  margin-top: 50px;
  margin-left: 30px;
}

#button-login:hover {
  background-position: right center !important;
  color: #ffffff !important;
  text-decoration: none !important;
}

footer {
  position: absolute;
  top: 95%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  align-items: center;
  z-index: 3 !important;
}

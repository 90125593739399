.fileDrop{
  display: flex;
  flex-direction: column;
  width: inherit;
  height: 200px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  text-align: center;
  margin-left: 0px;
}

.dragDropText{
  font-size: 1.5em;
  font-weight: bold;
  margin: auto;
}

.dragDropPreview{
  display: flex;
  flex-wrap: wrap;
  width: inherit;
  padding: 10px;
  margin: 0px;
  cursor: pointer;
  text-align: center;
}

.dragDropImage{
  flex: 25%;
  max-width: 25%;
  margin-bottom: 30px;
}

.dragDropLogo  svg{
  font-size: 1.5em;
}

.imageList{
  margin-top: 40px;
  width: inherit;
}

.imageItem:hover{
  cursor: pointer;
  opacity: 0.3;
}

.imageContainer:hover .dragDropIconDelete{
  cursor: pointer;
  display: block;
}

.dragDropIconDelete:hover{
  color: tomato;
  cursor: pointer;
  display: block;
}

.imageContainer .dragDropIconDelete{
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}







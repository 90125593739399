@media print {
	.not-printable{
		display: none !important;
	}
}
@page { size: auto;  margin: 8mm;  }

.containerReport {
  background-color: #fff;
  color : #000;
  width: 1000px;
  font-family: arial, sans-serif;
  border: solid rgb(187, 186, 186) 1px;
  margin-left: auto; 
  margin-right: auto;
  padding: 14px;
}

.containerReport .containerImg {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.containerHeader {
  display: flex;
}

.headerLeft {
  padding-left: 20px;
}

.headerRight {
  margin-left: auto;
  padding-right: 10px;
}

.containerBody {
  text-align: center;
  margin-top: 20px;
}

.containerBody table {
  width: 100%;
}

.containerTable {
  margin-top: 30px;
  border: solid rgb(187, 186, 186) 1px;
  border-right: none;
  border-left: none;
}

.tdCompra,
.thCompra {
  padding: 8px;
  text-align: left;
}

.trCompra:nth-child(even) {
  background-color: #dddddd;
}

.tableFornecedor tr {
  background-color: #fff !important;
}

.tableFornecedor > tr > th {
    width: 1%;
  }

.assinaturas {
  display: flex;
  margin-top: 90px;
  gap: 100px;
  justify-content: center;
}

.linha {
  width: 350px;
  border: none;
  border-top: solid rgb(8, 8, 8) 1px;
}

.containerFooter {
  margin-top: 30px;
  margin-left: 10px
}
